const BASE_URL =
    (process.env.REACT_APP_API_ROOT_CRM || process.env.REACT_APP_API_ROOT_DEFAULT) + '/crm/';

export const urls = {
    dashboard_line_detail: BASE_URL + 'dashboard/line_detail/',
    dashboard_dpd: BASE_URL + 'dashboard/dpd/',
    dashboard_summary: BASE_URL + 'dashboard/summary/',

    onboarding_leads: BASE_URL + 'onboarding/leads/',
    onboarding_gst_directors: BASE_URL + 'onboarding/leads/:lead_id/directors',

    documents: BASE_URL + 'documents/',
    document_details: BASE_URL + 'documents/:id/',

    leads: BASE_URL + 'leads/',
    lead_details: BASE_URL + 'leads/:id/',

    leads_download_excel: BASE_URL + 'leads/download_excel/',
    leads_download_gst_report: BASE_URL + 'leads/download_gst_report/',

    leads_bulk_create: BASE_URL + 'leads/bulk_create/',
    leads_bulk_update: BASE_URL + 'leads/bulk_update/',

    lead_submit: BASE_URL + 'leads/:id/validate/',
    lead_run_bre: BASE_URL + 'leads/:id/programs/:program_id/run_bre',
    run_routing: BASE_URL + 'leads/:id/routed_lending/',

    lead_programs: BASE_URL + 'leads/:id/programs/',
    lead_map_program: BASE_URL + 'leads/:id/program/',
    lead_program: BASE_URL + 'leads/:id/programs/:program_id/',

    lead_service_accounts: BASE_URL + 'leads/:id/service_accounts/',
    lead_program_accounts: BASE_URL + 'leads/:id/programs/:program_id/service_accounts/',

    applicants: BASE_URL + 'applicants/',
    applicants_details: BASE_URL + 'applicants/:id/',
    applicants_ping_bureau: BASE_URL + 'applicants/:id/bureau/',
    applicant_gst_analytics: BASE_URL + 'applicants/:id/gst-analytics/',
    applicant_itr: BASE_URL + 'applicants/:id/itr/',

    addresses: BASE_URL + 'addresses/',
    address_details: BASE_URL + 'addresses/:id/',

    banks: BASE_URL + 'banks/',
    bank_details: BASE_URL + 'banks/:id/',
    bank_statement_parse_initiate: BASE_URL + 'banks/statement/parse/initiate/:id/',

    comments: BASE_URL + 'comments/',

    vendors: BASE_URL + 'vendors/',
    vendors_gst: BASE_URL + 'vendors/gstin/',
    vendors_gst_filing: BASE_URL + 'vendors/gstin/filing/',
    gst_analytics_data: BASE_URL + '/vendors/gst-analytics/:id',
    vendors_pan: BASE_URL + 'vendors/pan/',
    vendors_matrix: BASE_URL + 'leads/:id/verification_matrix/',

    onboarding_leads_verify: BASE_URL + 'onboarding/leads/verify/',

    lookup_pincode: BASE_URL + 'addresses/pincode/:value',
    lookup_ifsc: BASE_URL + 'banks/ifsc/:value',

    gst_analytics_request: BASE_URL + 'leads/create-gst-request/:id',
    gst_analytics_otp_submit: BASE_URL + 'leads/gst-otp-verification/:id',
    gst_analytics_report_fetch: BASE_URL + 'leads/fetch-gst-report/:id',
};

export const lead_flow = {
    kyc_pending: [{ status: 'kyc_completed', label: 'Complete KYC', url: urls.lead_submit }],
    kyc_verification: [{ status: 'kyc_completed', label: 'Complete KYC', url: urls.lead_submit }],
};
